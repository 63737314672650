import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';

import { stateRequest } from './models/enum';
import { Applicants, DataBusiness, Response, UUID, UrlLambdaApi, UrlLambdaApiBus, UrlLambdaProduction} from './models/recruitment.model';
import { TransformBusinessPipe } from './pipes/recruitment.pipe';


@Injectable({
  providedIn: 'root'
})
export class RecruitmentService {

  public loadingSearch = false;

  // Esta variable contiene la info de la empresa.
  public businessInfo: DataBusiness;

  public stateGetBusiness: number = stateRequest.notExcuted;

  // Contiene la informacion de los aplicantes a los proyectos
  public applicantsInfo:Array<Applicants> = [];

  isOwner: boolean = false;

  constructor(
    private _appService:AppService,
    private http: HttpClient,
  ) { }

  private getHeadersLambda(): any {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this._appService.token
    })
    return ({ headers: headers });
  }

  // SERVICIO PARA LAS EMPRESAS
  /**Traer la empresa
   * @param id de la empresa es opcional
   */
  getBusiness(id?: any): Observable<any> {
    const url = id
      ? `${UrlLambdaApi}/business/"${id}"`
      : `${UrlLambdaApi}/business`;
    return this.http.get(url, this.getHeadersLambda()).pipe(
      map((response: any) => {
        const { status, data } = response;

        const transformBusinessPipe = new TransformBusinessPipe();
        this.stateGetBusiness = status
          ? stateRequest.excuted
          : stateRequest.error;
        
        this.businessInfo = status ? transformBusinessPipe.transform(data[0]) : null;
        
        return { valid: status, print: this.businessInfo };
      }),
      tap((response) => {
        if (id && response.valid) {
          this.isOwner = this._appService.user_data.id == this.businessInfo.ownerId;
        }
      }),
      retry(3),
      catchError(this.handleError<any>("getBusiness", []))
    );
  }

  /**Editar la empresa
   * @param id de la empresa es opcional,
   * @data los datos a editar
   */
  updateBusiness(data: any): Observable<any> {
    const url = `${UrlLambdaApi}/business/"${this.businessInfo.id}"`;
    return this.http.put(url, data, this.getHeadersLambda()).pipe(
      map((response: any) => {
        return response;
      }),
      retry(3),
      catchError(this.handleError<any>("putBusiness", []))
    );
  }

  searchUsers(searchString, start=0):Observable<Response> {
    let queryParam = `search_string="${searchString}"&start=${start}`

    return this.http.get(`${UrlLambdaApi}/users/search?${queryParam}`, this.getHeadersLambda()).pipe(
      map((response: any) => {
        return response
      }),
      retry(3),
      catchError(this.handleError<any>('getUser', []))
    );
  }

  // **Buscar aplicantes por medio del filtro de categoria */
  public searchApplicantsFilter(searchString):Observable<Response> {
    let queryParam = `search_string="${searchString}"`

    return this.http.get(`${UrlLambdaApiBus}/hr/applications/filter?${queryParam}`, this.getHeadersLambda()).pipe(
      map((response: any) => {
        return response
      }),
      retry(3),
      catchError(this.handleError<any>('ApplicantsFilter', []))
    );
  }

  public searchCourse(course):Observable<Response>{
    let queryParam = `search_string="${course}"`

    return this.http.get(`${UrlLambdaProduction}/cursos/search?${queryParam}`, this.getHeadersLambda()).pipe(
      map((response: any) => {
        return response
      }),
      retry(3),
      catchError(this.handleError<any>('searchCourse', []))
    );
  }

  /**
   * Recibe un string como parametro para verificar si es un uuid o no.
   * @param param Recibe un string como parametro
   * @returns Boolean
   */
  public isUUID(param: string): Boolean {
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    return regexExp.test(param)
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.log('%cerror::', 'color:red', error); // log to console instead
      // (error.error); // log to console instead
      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
