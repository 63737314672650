import { Pipe, PipeTransform } from '@angular/core';
import { Advertisement, VacanciesModel } from '../models/recruitment.model';
import { CampaignPipe } from './campaign.pipe';
import { GroupPipe } from './group.pipe';

@Pipe({
  name: 'vacancie'
})
export class VacanciePipe implements PipeTransform {

  transform(vacancy: any): VacanciesModel  {
    const jobCardTransformed = this.transformJobCard(vacancy.job_card);

    const dataVacancies: VacanciesModel = {
      id: vacancy.id,
      businessId: vacancy.business_id,
      departmentId: vacancy.department_id,
      jobId: vacancy.job_id,
      name: vacancy.name,
      favorite: vacancy.favorite,
      status: vacancy.status,
      lastActivity: vacancy.last_activity,
      countNew: vacancy.count_new,
      countAnswereds: vacancy.count_answereds,
      countContacteds: vacancy.count_contacteds,
      countCandidates: vacancy.count_candidates,
      owner: {
        userName: vacancy.owner?.user_name,
        countryId: vacancy.owner?.country_id,
        email: vacancy.owner?.email,
        userImage: vacancy.owner?.user_image,
        userId: vacancy.owner?.user_id
      },
      jobCard : jobCardTransformed,
      request: vacancy.request,
      spent: vacancy.spent,
      views: vacancy.views,
    };
    return dataVacancies;
  }

  public transformJobCard(jobCard: any): Advertisement {
    let city:string='';
    let country:string='';
    
    const ubicationParts = jobCard?.ubication?.split(',');
    
    if(ubicationParts){
      city = ubicationParts[0]?.trim();
      country = ubicationParts[1]?.trim();
    }

    const formated:Advertisement = {
      jobType: jobCard?.job_type,
      city: city,
      ubication: country, 
      description: jobCard?.description,
      aptitudes: jobCard?.aptitudes || [],
      quiz: Array.isArray(jobCard?.quiz) ? '' : jobCard?.quiz,
      priority: jobCard?.priority,
      modality: jobCard?.modality,
    };
    
    if (jobCard.campaign) formated.campaign = CampaignPipe.prototype.transform(jobCard?.campaign)

    if (jobCard.group) formated.group = GroupPipe.prototype.transform(jobCard?.group)

    return formated;
  }
}

@Pipe({
  name: 'vacancieRequest'
})
export class VacancieRequestPipe implements PipeTransform {

  transform(vacancy: any)  {
    const jobCardReguest = this.jobCardReguest(vacancy.jobCard);

    const dataVacancies = {
      id: vacancy.id,
      business_id: vacancy.businessId,
      department_id: vacancy.departmentId,
      job_id: vacancy.jobId,
      name: vacancy.name,
      favorite: vacancy.favorite,
      status: vacancy.status,
      last_activity: vacancy.lastActivity,
      count_new: vacancy.countNew,
      count_answereds: vacancy.countAnswereds,
      countContacteds: vacancy.countContacteds,
      count_candidates: vacancy.countCandidates,
      owner: {
        user_name: vacancy.owner.name,
        country_id: vacancy.owner.countryId,
        email: vacancy.owner.email,
        user_image: vacancy.owner.image,
        user_id: vacancy.owner.id
      },
      jobCard : jobCardReguest,
      request: vacancy.request,
      spent: vacancy.spent,
      views: vacancy.views,
    };
    return dataVacancies;
  }

  public jobCardReguest(jobCard: Advertisement): any {
    return {
      job_type: jobCard.jobType,
      ubication: jobCard.city + jobCard.ubication,
      description: jobCard.description,
      aptitudes: jobCard.aptitudes,
      quiz: jobCard.quiz,
      priority: jobCard.priority,
      modality: jobCard.modality,
    };
  }
}


