import { Pipe, PipeTransform } from "@angular/core";
import { Course, Route, Section } from "../models/model";

@Pipe({
  name: "durationPipe",
})
export class DurationPipe implements PipeTransform {
  transform(route: Route): string {
    let totalDuration = 0;
    const courses: Course[] = CoursesPipe.prototype.transform(route);
    if (courses.length > 0) {
      totalDuration = route.sections.reduce((acc, section) => {
        const sectionDuration = section.courses.reduce(
          (acc, course) => acc + course.duration,
          0
        );
        return acc + sectionDuration;
      }, 0);
    }
    const hours = Math.floor(totalDuration / 3600);
    const minutes = Math.floor(totalDuration / 60) % 60;
    return (
      (hours > 9 ? "" + hours : "0" + hours) +
      "h " +
      (minutes > 9 ? "" + minutes : "0" + minutes) +
      "min"
    );
  }
}

@Pipe({
  name: "courseDurationPipe",
})
export class CourseDurationPipe implements PipeTransform {
  transform(duration: number): string {
    const hours = Math.floor(duration / 3600);
    return (hours > 9 ? "" + hours : "0" + hours) + " horas ";
  }
}

@Pipe({
  name: "courses",
})
export class CoursesPipe implements PipeTransform {
  transform(route: Route): Course[] {
    const courses: Course[] = [];
    if (route.sections) {
      route.sections.forEach((section) => {
        if (section.courses) {
          section.courses.forEach((course) => {
            courses.push(course);
          });
        }
      });
    }
    return courses;
  }
}

@Pipe({
  name: "transformRoute",
})
export class TransformRoutePipe implements PipeTransform {
  transform(data: any, isRouteList: boolean = true): Route | Route[] {
    if (isRouteList) {
      const routes: Route[] = [];
      data.forEach((item: any) => {
        const route: Route = this.transformRouteItem(item);
        routes.push(route);
      });
      return routes;
    } else {
      return this.transformRouteItem(data);
    }
  }

  private transformRouteItem(item: any): Route {
    const route: Route = {
      id: item.id,
      bussiness_id: item.bussiness_id,
      name: item.name,
      date_create: item.date_create,
      description: item.description,
      type: item.type,
      sections: [],
    };

    item.sections?.forEach((section: any) => {
      const newSection: Section = {
        date_create: section.date_create,
        id: section.id,
        name: section.name,
        route_id: section.route_id,
      };

      newSection.courses = [];
      section.cursos?.forEach((course: any) => {
        const newCourse: Course = {
          id: course.curso_id,
          duration: course.info?.duracion,
          image: course.info?.imagen,
          name: course.info?.nombre,
          route_id: course.route_id,
          section_id: course.section_id,
          slug: course.info?.slug,
          order: course?.order,
        };
        newSection.courses.push(newCourse);
      });

      newSection.courses.sort((a, b) => {
        return parseInt(a.order) - parseInt(b.order);
      });
      route.sections.push(newSection);
    });

    return route;
  }
}

@Pipe({
  name: "countSectionsCoursesPipe",
})
export class CountSectionsCoursesPipe implements PipeTransform {
  transform(data: Route): string {
    if (!data.sections) {
      return "0 secciones";
    }

    const sections = data.sections.length;
    const courses = CoursesPipe.prototype.transform(data).length;
    return `${sections} secciones - ${courses} cursos`;
  }
}

@Pipe({
  name: "courseTransformPipe",
})
export class CourseTransformPipe implements PipeTransform {
  transform(data: any): Course[] {
    const courses: Course[] = [];
    data?.forEach((item: any) => {
      const course: Course = {
        id: item.id,
        duration: item.fields.duration[0],
        name: item.fields.name[0],
        slug: item.fields.slug[0],
      };
      courses.push(course);
    });
    return courses;
  }
}
