import { Component, Inject, ViewChild, ViewContainerRef } from '@angular/core';
import { AppService } from './app.service';
import { PushNotificationService } from './shared/service/push-notification.service';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './auth/services/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  //data del modulo de chat para poder llamar el módulo cuando se necesite
  chatComponent = {
    moduleId: 'chat-module',
    selector: 'chat-room'
  };

  commentsModalComponent = {
    moduleId: 'comments-modal-module',
    selector: 'comments-modal'
  };

  notifications: any = {
    count: 0,
    desk: []
  }

  user_routes = ['login', 'add', 'forgetpwd', 'confirm', 'resend', 'reset', 'resend_activation', 'confirmForgotPassword'];

  @ViewChild('liveChat', { read: ViewContainerRef }) entry: ViewContainerRef;


  constructor(
    @Inject(Router) public _router: Router,
    public _appService: AppService,
    public _authService: AuthService,
    public pushNotification: PushNotificationService,
  ) {
    document.documentElement.setAttribute("data-browser", navigator.userAgent);

    this._router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          this._appService.url_route = event.url.replace('/', '').split('/')[0].split('?')[0];
        }
      }
    );

    this._authService.isUserAuthenticated().subscribe();
  }


  ngOnInit() {
    console.log('App Edutin Recruitment Version 3');

    if ('serviceWorker' in navigator && Notification.permission === 'granted') {
      this.pushNotification.receiveMessage().subscribe(payload => {
      })
    }
  }


  // Start notifications push
  subscribe_user_notification_push(response): void {
    if (this._appService.show_desktop_notification && response) {
      if ('serviceWorker' in navigator) {
        this.pushNotification.requestPermission().then(
          (token) => {
            this._appService.show_desktop_notification = false;
            if (token) {
              // console.log('Permission granted! Save to the server!', token);
              this.pushNotification.receiveMessage().subscribe(payload => {
                // console.log(payload);
                this.notifications.count++;
                this.notifications.desk.unshift(JSON.parse(payload.data.default));
              })
              this.updateSubscriptionOnServer(token);
            }
          }, (permission) => {
            if (permission === "default" || permission === "denied") {
              this._appService.show_desktop_notification = false;
            }
          }
        );
      }
    } else {
      this._appService.show_desktop_notification = false;
      localStorage.setItem('desk_push', JSON.stringify(false));
      let date = new Date();
      date.setDate(date.getDate() + 7);
      localStorage.setItem("date_push", date.toDateString());
    }
  }

  updateSubscriptionOnServer(token) {
    if (this._appService.user_data && token) {
      let body = {
        "data": {
          "user_id": parseInt(this._appService.user_data.id),
          "token": token
        }
      };
      this._appService.updateSubscriptionOnServer(JSON.stringify(body)).subscribe();
    }
  }

  show_notification(desk, index) {
    if (desk) {
      this.goToLink(desk.url);
      this.notifications.count--;
      this.notifications.desk.splice(index, 1);
    }
  }

  goToLink(url: string): void {
    window.open(url, "_blank");
  }

  verifyAuthenticatedUserEmail() {

    //Verificar si lo que es aquí es el correo o es el telefono

    const { email, phone_number } = this._appService.cognitoUserAuthenticated.signInUserSession.idToken.payload;

    if (email != null && email != '') {
      this._appService.verifyEmail = email;
      this._router.navigate(['/user/confirm']);
    }
  }
}
