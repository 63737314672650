<div id="nav-bar" [ngClass]="_appService.url_route" class="training" >
  <div class="content">

    <div class="logo">
      <span class="item">
        <img src="https://d3puay5pkxu9s4.cloudfront.net/pages/edutin-isotype-positive.svg" alt="Edutin Recruitment">
      </span>

      <span class="item">
        <div class="logo-label" *ngIf="isRecruitment">Edutin Recruitment</div>
        <div class="logo-label" *ngIf="!isRecruitment">Edutin Business</div>
      </span>
    </div>

    <app-menu class="menu"></app-menu>

    <div [ngClass]="{'searchOpen': showSearch}" class="options-2">

      <!-- Profile -->
      <div *ngIf="_appService.user_session==1" (clickOutsideDdown)="showProfile=false" class="profile dropdown">
        <div (click)="toggleProfile()" [ngClass]="{'open':showProfile}" class="item">
          <img #userAvatar [src]="_appService.user_data.picture"
            (error)="userAvatar.src= 'https://d3puay5pkxu9s4.cloudfront.net/Users/'+(_appService.user_data.role == 'propietario' ? 'professor/small_imagen.jpg' : 'default/small_imagen.jpg')"
            [className]="_appService.user_data.role | userClassName">
        </div>
        <ul class="menu">
          <li>
            <a (click)="showProfile=false" [routerLink]="['/user/profile', _appService.user_data.id]">
              <div class="row">
                <img [src]="_appService.user_data.picture" #menuUserAvatar
                  (error)="menuUserAvatar.src= 'https://d3puay5pkxu9s4.cloudfront.net/Users/'+(_appService.user_data.role == 'propietario' ? 'professor/small_imagen.jpg' : 'default/small_imagen.jpg')"
                  [className]="_appService.user_data.role | userClassName">
                <div class="name">
                  <h3>{{_appService.user_data.name | cutUserName: '2'}}</h3>
                  <h4>{{_appService.user_data.role | roleName}}</h4>
                </div>
              </div>
            </a>
          </li>

          <li>
            <a (click)="showProfile=false" [routerLink]="['/business/profile']">Perfil de Empresa</a>
          </li>
          <li><a (click)="showProfile=false" [routerLink]="['/user/edit']">Editar perfil</a></li>
          <li>
            <a (click)="logout(_appService.url_Base+'/users/logout')">Cerrar sesión</a>
          </li>
        </ul>
      </div>

      <!-- Profile Loading -->
      <ul *ngIf="_appService.user_session==-1" class="loading-icons-nav">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>

    </div>

    <!-- Menu mobile responsive -->
    <div (clickOutsideDdown)="showMenu=false" [ngClass]="{'active': showMenu}" class="menu-mobile dropdown">
      <div (click)="toggleMenu()" class="btn-menu">
        <svg viewBox="0 0 100 100" width="55" class="svg-menu">
          <path
            d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"
            class="line top"></path>
          <path d="m 30,50 h 40" class="line middle"></path>
          <path
            d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"
            class="line bottom"></path>
        </svg>
      </div>
      <div [ngClass]="{'open':showMenu}" class="menu">
        <!-- Profile no user -->
        <div *ngIf="_appService.user_session==0" class="btn-no-user">
          <a [routerLink]="['/user/login']" class="login">Acceder</a>
          <a [routerLink]="['/user/add']" class="add">Crear cuenta</a>
          <!-- <a href="https://edutin.com/users/login" class="login">Acceder</a>
          <a href="https://edutin.com/users/add" class="add">Crear cuenta</a> -->
        </div>
        <ul class="about-we">
          <li>
            <a href="https://edutin.com/about-us" target="_blank" class="link-a">Sobre
              Edutin Academy</a>
            <div>Conoce nuestra misión y valores.</div>
          </li>
          <li>
            <a href="https://edutin.com/history" target="_blank" class="link-a">Nuestra
              historia</a>
            <div>Conoce nuestra historia.</div>
          </li>
          <li>
            <a href="https://edutin.com/virtual-campus" target="_blank" class="link-a">Campus virtual</a>
            <div>Conoce nuestra infraestructura tecnológica.</div>
          </li>
          <li>
            <a href="https://edutin.com/academy-contents" target="_blank" class="link-a">Contenidos académicos</a>
            <div>Conoce el tipo de contenidos académicos utilizados en nuestra biblioteca de
              cursos.</div>
          </li>
          <li>
            <a href="https://edutin.us" target="_blank" target="_blank" class="link-a">Empleos</a>
            <div>Explore nuestros puestos vacantes, encuentre un trabajo que le encante y postule.</div>
          </li>
          <li>
            <a href="https://edutin.com/certificado-de-estudios" target="_blank" class="link-a">Certificados</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
