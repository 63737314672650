<div class="options" *ngIf="currentRoute.includes('hr')" [routerLinkActiveOptions]="{exact:true}">
  
  <a class="option" routerLink="/hr/{{businessId}}/vacancies" routerLinkActive="active-link">
    <div class="button">
      <span class="button__lable">Vacantes</span>
      <span class="icon-plus button__icon"></span>
      <div class="border-bottom"></div>
    </div>
  </a>

  <a class="option" routerLink="/hr/{{businessId}}/candidate-search" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">
    <div class="button">
      <span class="button__lable">Búsqueda</span>
      <span class="icon-plus button__icon"></span>
      <div class="border-bottom"></div>
    </div>
  </a>

  <a class="option" routerLink="/hr/{{businessId}}/mails" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">
    <div class="button">
      <span class="button__lable">Correos</span>
      <span class="icon-plus button__icon"></span>
      <div class="border-bottom"></div>
    </div>
  </a>

</div>
