import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { SharedService } from './shared.service';

// import { Translate_Module } from '../translate/translate.module';
import { FooterComponent } from './components/footer/footer.component';


import { CutUserNamePipe, GetProgress, RoleName, UserClassName, UrlImage, shoppingCarType, notificationsDate, messageDate, categoryName, CssChildHeight, CourseConfigUrl, GroupNamePipe, CheckPermissionsPipe } from './shared.pipe';
import { ClickOutsideDdownDirective } from './directives/clickOutsideDdown.directive';
import { RouterModule } from '@angular/router';
import { NotificationsComponent } from './components/nav-bar/notifications/notifications.component';
import { ShoppingCartComponent } from './components/nav-bar/shopping-cart/shopping-cart.component';
import { ComboboxComponent } from './components/combobox/combobox.component';
import { CustomImageComponent } from './components/custom-image/custom-image.component';
import { SearchBarComponent } from './components/shearch-bar/shearch-bar.component';
import { StudentItemComponent } from './components/student-item/student-item.component';
import { MenuComponent } from '../recruitment/components/menu/menu.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { DragDropComponent } from './components/drag-drop/drag-drop.component';
import { ModalMailComponent } from '../recruitment/components/modals/modal-mail/modal-mail.component';
import { MailSkeletonComponent } from '../recruitment/skeletons/mail-skeleton/mail-skeleton.component';
import { RemoveModalComponent } from '../recruitment/components/modals/remove-modal/remove-modal.component';
import { TooltipDirective } from './directives/tooltip.directive';

@NgModule({
  declarations: [
    NavBarComponent,
    FooterComponent,
    CutUserNamePipe, GetProgress, RoleName, UserClassName, UrlImage, shoppingCarType, notificationsDate, messageDate, categoryName, GroupNamePipe, CssChildHeight, CourseConfigUrl,
    ClickOutsideDdownDirective,
    NotificationsComponent,
    ShoppingCartComponent,
    MenuComponent,
    CheckPermissionsPipe,
    ComboboxComponent,
    CustomImageComponent,
    SearchBarComponent,
    StudentItemComponent,
    DragDropComponent,
    ModalMailComponent,
    MailSkeletonComponent,
    RemoveModalComponent,
    TooltipDirective
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    RouterModule,
  ],
  exports: [
    NavBarComponent,
    FooterComponent,
    NotificationsComponent,
    ShoppingCartComponent,
    ClickOutsideDdownDirective,
    categoryName, CssChildHeight, CourseConfigUrl, GroupNamePipe, UrlImage,
    CheckPermissionsPipe,
    ComboboxComponent,
    CustomImageComponent,
    SearchBarComponent,
    DragDropComponent,
    ModalMailComponent,
    RemoveModalComponent,
    TooltipDirective
  ],
  providers: [
    SharedService
  ]
})
export class SharedModule { }
