import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  public businessId: string;
  public currentRoute: string;

  constructor(
    public _router: Router,
    
  ) { }

  ngOnInit(): void {

    // el tercer segmento de la ruta actual
    this.currentRoute = this._router.url;
    
    this._router.events.pipe(
      filter((event) => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
            this.businessId = event.url.split("/")[2];
            this.currentRoute = event.url;
          });
  }



}
