import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { RecruitmentService } from 'src/app/recruitment/recruitment.service';
import { TrainingService } from 'src/app/training/training.service';

@Injectable({
  providedIn: 'root'
})
export class RecruitmentGuard  {

  constructor(
    private _trainService: TrainingService,
    private _recruitmentService: RecruitmentService
  ) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Tomar el id que está en la ruta
    const businessId  = state.url.split("/")[2];

    // Toma el valor de true o false dependiendo de si se obtuvo los datos de la empresa
    const business = await this.businessInfo(businessId);

    // Varificamos que el stateGetBusiness esté en 1
    if (this._recruitmentService.stateGetBusiness == 1 ) {
      // Verificamos que los datos de la empresa ya los tenemos
      if (business) {
        return true;
      }
    }
    else {
      window.location.href = 'https://business.edutin.com/business/create';
      this._trainService.loading = false;
      return false;
    }
  }

  businessInfo(id):Promise<boolean> {
    return new Promise((resolve) => {
      if (!this._recruitmentService.businessInfo) {
        this._recruitmentService.getBusiness(id).subscribe(response => {
          const { valid } = response;
          resolve (valid)
        })
      } else {
        resolve (Boolean(this._recruitmentService.businessInfo))
      }
    })
  }

}
