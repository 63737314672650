import { Pipe, PipeTransform } from "@angular/core";
import { DataBusiness, Stage } from "../models/recruitment.model";
import { VacanciesModel } from "../models/recruitment.model";
import { VacanciesService } from "../services/vacancies.service";

@Pipe({ name: "formatedText" })
export class TagsRegularExpr implements PipeTransform {
    transform(text: string): string {
        const pattern = /\[uid:\s*\d+\]/g;
        const transformedText = text.replace(pattern, '');
        return transformedText;
    }
}

@Pipe({ name: "transformBusinessPipe" })
export class TransformBusinessPipe implements PipeTransform {
    transform(data: any): DataBusiness {
        const transformStage = new TransformStagePipe();
        const stages = data.hr_stages ? data.hr_stages.filter(stage => Boolean(stage)) : [];
        const hrStages = stages.map(stage => transformStage.transform(stage)).sort((a, b) => a.order - b.order);
        const busi: DataBusiness = {
            id: data.id,
            name: data.name,
            email: data.email,
            ownerId: data.owner_id,
            logo: data.logo,
            description: data.description,
            dateCreate: data.date_create,
            ownerName: data.owner_name,
            rubro: data.rubro,
            countryId: data.country_id,
            hrStages
        };
        return busi;
    }
}

@Pipe({ name: "transformStagePipe", })
export class TransformStagePipe implements PipeTransform {
    transform(data: any): Stage {
        return {
            id: data.id,
            name: data.name,
            notificationId: data.notification_id,
            order: Number(data.order),
            mailId: data.mail_id,
            value: data.value,
        }
    }
}

@Pipe({ name: "transformStageRequest", })
export class TransformStagePipeRequest implements PipeTransform {
    transform(data: Stage) {
        return {
            id: data.id,
            name: data.name,
            notification_id: data.notificationId,
            order: Number(data.order),
            mail_id: data.mailId,
            value: data.value,
        }
    }
}

@Pipe({ name: "filterVacancies" })
export class FilterVacancies implements PipeTransform {

    constructor(private _vacanciesService: VacanciesService) { }

    transform(vacancies: Array<VacanciesModel>, page, filtered = 'all', items = this._vacanciesService.pagination): Array<VacanciesModel> {

        let storageVacancies = {
            'all': vacancies,
            'favorites': this._vacanciesService.favorites,
            'archived': this._vacanciesService.archived,
            'searched':this._vacanciesService.searched
        }

        let data_return = storageVacancies[filtered];

        const start = items * (page - 1);
        const track = start + (items)

        return data_return.slice(start, track);
    }
}

@Pipe({
    name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

    transform(value: string): string {
        const date = new Date(value);
        const now = new Date();
        const seconds = Math.round((now.getTime() - date.getTime()) / 1000);
        const minutes = Math.round(seconds / 60);
        const hours = Math.round(minutes / 60);
        const days = Math.round(hours / 24);

        if (seconds < 300) {
            return 'Hace un momento';
        } else if (minutes < 60) {
            return `Hace ${minutes} minutos`;
        } else if (hours < 24) {
            return `Hace ${hours} horas`;
        } else if (days < 30) {
            return `Hace ${days} días`;
        } else {
            return `Desde ${date.toLocaleDateString('es-ES', { month: 'short', day: 'numeric', year: 'numeric' })}`;
        }
    }
}

@Pipe({ name: 'toSnakeCase' })
export class ToSnakeCase implements PipeTransform {
    transform(value: any): any {

        // Validar si value es undefined o null
        if (value === undefined || value === null) {
            return value;
        }

        
        if (Array.isArray(value)) {
            // Si es un array, aplica la transformación a cada elemento del array recursivamente
            return value.map(item => this.transform(item));
        } else if (typeof value === 'object') {
            // Si es un objeto, aplica la transformación a cada propiedad del objeto recursivamente
            let snakeCaseObject = {};
            Object.keys(value).forEach(key => {
                snakeCaseObject[this.camelToSnake(key)] = this.transform(value[key]);
            });
            return snakeCaseObject;
        } else {
            // Si es un valor primitivo, simplemente devuelve el valor
            return value;
        }
    }

    camelToSnake(key) {
        if (/[A-Z]/.test(key[0])) {
            return key[0] + this.toSnake(key.slice(1));
        } else {
            return this.toSnake(key);
        }
    }

    toSnake(key){
        return key.replace(/([A-Z])/g, '_$1').toLowerCase();
    }
}
