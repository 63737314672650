import { Pipe, PipeTransform } from '@angular/core';
import { Campaign } from '../models/recruitment.model';

@Pipe({
  name: 'campaign'
})
export class CampaignPipe implements PipeTransform {

  transform(campaign:any): Campaign {
    let dataCampaign:Campaign={
      id:campaign.id,
      category:campaign.category,
      name:campaign.name,
      created:campaign.created,
      objective:campaign.objective,
      ownerId:campaign.owner_id,
      status:campaign.status
    }
    return dataCampaign;
  }
}
