import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, Subject, of } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { UUID, Response, VacanciesModel, UrlLambdaApiBus, qparamsVacancy } from '../models/recruitment.model';
import { VacanciePipe } from '../pipes/vacancie.pipe';
import { RecruitmentService } from '../recruitment.service';


@Injectable({
  providedIn: 'root'
})
export class VacanciesService {

  public loadingVacancies = true;
  public pagination: number = 4;  // Cantidad de items que se mostraran por paginas

  // Esta variable contiene la info de las vacantes.
  public vacancies: Array<VacanciesModel> = [];
  public favorites: Array<VacanciesModel> = [];
  public archived: Array<VacanciesModel> = [];
  public sharedVacancies: Array<VacanciesModel> = [];
  public searched: Array<VacanciesModel> = [];

  public currentFilter$: Subject<string> = new Subject<string>();

  currentVacancie$: Subject<VacanciesModel | null> = new Subject<VacanciesModel>();

  vacancyId: UUID;

  public evaluatedKeys = {
    all: null,
    favorites: null,
    archived: null,
    shared: null
  }

  public dataPages = {
    all: { totalPages: 1, currentPage: 1, items: 0, previouslyLoaded: [] },

    favorites: { totalPages: 1, currentPage: 1, items: 0, previouslyLoaded: [] },

    archived: { totalPages: 1, currentPage: 1, items: 0, previouslyLoaded: [] },
    
    shared: { totalPages: 1, currentPage: 1, items: 0, previouslyLoaded: [] },

    searched: { totalPages: 1, currentPage: 1, items: 0, previouslyLoaded: [] },
  }

  constructor(
    private _appService: AppService,
    private http: HttpClient,
    private _recruitmentService: RecruitmentService,
  ) {
  }

  private getHeadersLambda(): any {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this._appService.token
    })
    return ({ headers: headers });
  }

  /**
    * Realiza una petición HTTP GET para obtener vacantes basándose en los criterios especificados en `params`.
    * La función ajusta automáticamente el `business_id` al valor actual de la instancia antes de realizar la petición.
    * 
    * @param params Parámetros de consulta para filtrar las vacantes. Si no se proporciona, se utiliza un objeto vacío por defecto.
    * La estructura de `qparamsVacancy` es la siguiente:
    * ```
    * interface qparamsVacancy {
    *   id?: string;               // Opcional. Identificador de la vacante.
    *   business_id?: string;      // Opcional. Identificador del negocio asociado a las vacantes.
    *   favorite?: boolean;        // Opcional. Indica si filtrar por vacantes favoritas.
    *   invited?: boolean;         // Opcional. Filtra por vacantes a las que ha sido invitado.
    *   status?: string;           // Opcional. Estado actual de las vacantes a filtrar.
    *   LastEvaluatedKey?: any;    // Opcional. Paginación de resultados en la consulta.
    *   pages?: boolean;           // Opcional. Indica si se deben incluir datos de paginación.
    * }
    * ```
    * 
    * @returns Un `Observable` que resuelve a un objeto `Response` conteniendo:
    * - `status`: Estado de la respuesta HTTP.
    * - `data`: Datos de las vacantes obtenidas.
    * - `LastEvaluatedKey`: Clave del último elemento evaluado, útil para paginación.
    * - `error`: Mensaje de error en caso de fallar la petición.
    * 
    * La función reintenta la petición hasta 3 veces en caso de error antes de fallar definitivamente.
  */
  getVacancies(params: qparamsVacancy = {}): Observable<Response> {

    params.business_id = this._recruitmentService.businessInfo.id;

    // Construir la cadena de consulta a partir de los parámetros
    let queryString = this.buildQueryParams(params);
    
    let url = `${UrlLambdaApiBus}/hr/vacancies?${queryString}`;

    return this.http.get(url, this.getHeadersLambda()).pipe(
      map((response: any) => {
        const { status, data, LastEvaluatedKey } = response;
        const data_return: Response = status ? { status, data, LastEvaluatedKey } : { status, error: 'Ocurrió un error al encontrar las vacantes' }
        return data_return;
      }),
      retry(3),
      catchError(this.handleError<any>('getVacancies', []))
    );
  }

  /**
   * Solicita las páginas de vacantes disponibles basándose en los criterios especificados en `params`.
   * Esta función ajusta automáticamente el `business_id` al valor de la instancia actual y establece `pages` en `true`
   * para indicar que se solicitan datos de paginación.
   * 
   * @param params Parámetros de consulta para filtrar las páginas de vacantes. Si no se proporciona, se utiliza un objeto vacío por defecto.
   * La estructura de `qparamsVacancy` incluye:
   * ```
   * interface qparamsVacancy {
   *   id?: string;               // Opcional. Identificador de la vacante específica.
   *   business_id?: string;      // Opcional. Se sobrescribe automáticamente con el ID logueado.
   *   favorite?: boolean;        // Opcional. Filtra por vacantes marcadas como favoritas.
   *   invited?: boolean;         // Opcional. Filtra por vacantes a las que ha sido invitado.
   *   status?: string;           // Opcional. Filtra vacantes por su estado actual.
   *   LastEvaluatedKey?: any;    // Opcional. Usado para paginación de los resultados.
   *   pages: true;               // Establecido automáticamente para solicitar datos de paginación.
   * }
   * ```
   * 
   * @returns Un `Observable` que resuelve a un objeto `Response` que contiene:
   * - `status`: Estado de la respuesta HTTP.
   * - `data`: Datos de las páginas de vacantes obtenidas.
   * - `error`: Mensaje de error en caso de que la petición falle.
   * 
   * En caso de error, la función reintentará la petición hasta 3 veces antes de fallar definitivamente.
 */
  getPages(params: qparamsVacancy = {}): Observable<any> {
    params.business_id = this._recruitmentService.businessInfo.id;
    params.pages = true;

    let queryString = this.buildQueryParams(params);
    let url = `${UrlLambdaApiBus}/hr/vacancies?${queryString}`;

    return this.http.get(url, this.getHeadersLambda()).pipe(
      map((response: any) => {
        const { status, data } = response;
        const data_return: Response = { status, data }
        return data_return;
      }),
      retry(3),
      catchError(this.handleError<any>('getPages', []))
    );
  }

  formatVacancy(vacancy): VacanciesModel {
    return VacanciePipe.prototype.transform(vacancy);
  }

  assignLastEvaluatedKey() {
    // Función auxiliar para asignar el último key evaluado si hay más elementos
    const assignIfHasMoreItems = (list, dataPageKey) => {
      if (list.length < this.dataPages[dataPageKey].items) {
        const { id, business_id } = list.at(-1) ?? {}; // Utiliza .at(-1) para obtener el último elemento de forma segura
        this.evaluatedKeys[dataPageKey] = { id, business_id };
      }else{
        this.evaluatedKeys[dataPageKey] = null
      }
    };

    // Asignar last evaluated keys para favoritos y archivados si hay más elementos
    assignIfHasMoreItems(this.favorites, 'favorites');
    assignIfHasMoreItems(this.archived, 'archived');
  }

  /*Crear vacantes*
   * Retorna el proyecto que se crea data y status
   * @param data debe ser de tipo vacancyCreationData
   * @returns debe ser de tipo Response {status, data, error, item}
   */
  createVacancies(data:any):Observable<any>{
    let url= `${UrlLambdaApiBus}/hr/business/"${this._recruitmentService.businessInfo.id}"/vacancies`;

    return this.http.post(url, data, this.getHeadersLambda()).pipe(
      map((response: any) => {
        const { status, data } = response;

        const data_return: any = status ? { status, data } : { status, error: 'Ocurrió un error al crear la vacante' };
        return data_return;
      }),
      retry(3),
      catchError(this.handleError<any>('createVacancies', []))
    );
  }

  /**Edición de vacantes
   * @param vacancy_id debe ser de tipo UUID
   * @param data debe ser de tipo updateProject
   * @returns retorna el status y la cantidad de rows afectadas
   * */
  editVacancies(vacancyId, data: any): Observable<any> {
    let url=`/hr/business/"${this._recruitmentService.businessInfo.id}"/vacancies/"${vacancyId}"`;
    
    return this.http.put(UrlLambdaApiBus + url, { data }, this.getHeadersLambda())
      .pipe(map((response: any) => {
        return response;
      }),
        retry(3),
        catchError(this.handleError<any>('editVacancies', [])));
  }

  /**Eliminar vacantes
   *  @param vacancy_id debe ser de tipo UUID
   * @returns status y la cantidad de rows eliminadas
  */
  deleteVacancy(vacancyId:UUID){
    let url= `${UrlLambdaApiBus}/hr/business/"${this._recruitmentService.businessInfo.id}"/vacancies/"${vacancyId}"`;

    return this.http.delete(url, this.getHeadersLambda())
      .pipe(map((response: any) => {
        return response;
      }),
        retry(3),
        catchError(this.handleError<any>('deleteVacancy', [])));
  }

  /*Traer los paises*
   * Retorna la informacion de los paises con sus ciudades
   * @returns {status, data, error, item}
   */
  getCitiesByCountry(countryId?): Observable<Response> {

    countryId = countryId ? countryId : this._recruitmentService.businessInfo.countryId;

    let url = `${UrlLambdaApiBus}/business/countries/"${countryId}"`;

    return this.http.get(url, this.getHeadersLambda()).pipe(
      map((response: any) => {

        const { status, data } = response;

        const data_return: Response = status ? { status, data } : { status, error: 'Ocurrió un error al encontrar los paises' }

        return data_return;
      }),
      retry(3),
      catchError(this.handleError<any>('getCountries', []))
    );
  }

  /**
   * Construye una cadena de parámetros de consulta codificada para URL a partir de un objeto de parámetros dado.
   * 
   * Esta función itera sobre cada par clave-valor en el objeto `params`, realiza las siguientes operaciones:
   * - Filtra los pares donde el valor es `null`, ya que no se deben incluir en la cadena de consulta.
   * - Convierte los valores de tipo `string` en valores encerrados entre comillas.
   * - Convierte los valores de tipo `object` (incluidos los arrays) en cadenas JSON.
   * - Mantiene los demás tipos de valores tal cual (numéricos, booleanos, etc.).
   * - Codifica tanto las claves como los valores para asegurar la compatibilidad con URL.
   * 
   * Los pares clave-valor codificados se unen con `&`, formando la cadena de consulta final que se puede añadir a una URL.
   * 
   * @param params Objeto que contiene los parámetros que se quieren convertir en una cadena de consulta. Cada clave del objeto representa un parámetro de consulta, y el valor asociado a esa clave representa el valor del parámetro.
   * @returns Una cadena de caracteres que representa los parámetros de consulta codificados para URL, lista para ser añadida a una URL de petición HTTP.
 */
  buildQueryParams(params: Object): string {
    const encodedParams = Object.entries(params)
      .filter(([key, value]) => value !== null && value !== undefined) // Filtrar entradas con valores null
      .map(([key, value]) => {
        // Determinar el formato del valor
        let formattedValue;
        if (typeof value === 'string') {
          // Encerrar entre comillas si el valor es un string
          formattedValue = `"${value}"`;
        } else if (typeof value === 'object') {
          // Convertir en cadena JSON si el valor es un objeto
          formattedValue = JSON.stringify(value);
        } else {
          formattedValue = value;
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(formattedValue)}`;
      })
      .join('&');

    return encodedParams
  }

  /**
   * Recibe un string como parametro para verificar si es un uuid o no.
   * @param param Recibe un string como parametro
   * @returns Boolean
   */
  public isUUID(param: string): Boolean {
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    return regexExp.test(param)
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure

      console.log('%cerror::', 'color:red', error); // log to console instead
      // (error.error); // log to console instead
      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
