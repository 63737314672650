import { RouterModule, Routes } from '@angular/router';
import { CustomPreloadingStrategyService } from './custom-preloading-strategy.service';
import { RecruitmentGuard } from './shared/guards/recruitment.guard';
import { RegisterTrainingGuard } from './shared/guards/register-training.guard';
import { TrainingGuard } from './shared/guards/training.guard';

export const APP_ROUTES: Routes = [
    { path: 'hr/:id', loadChildren: ()=> import('./recruitment/recruitment.module').then(m => m.RecruitmentModule), canActivate:[TrainingGuard, RecruitmentGuard]},

    { path: 'business', loadChildren: ()=> import('./dashboard/dashboard.module').then(m => m.DashboardModule), canActivate:[TrainingGuard ,RegisterTrainingGuard, RecruitmentGuard]},

    { path: 'register', loadChildren: ()=> import('./register/register.module').then(m => m.RegisterModule)},

    { path: '', redirectTo: 'business', pathMatch: 'full' },
    { path: '**', redirectTo: 'business', pathMatch: 'full' },
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, { preloadingStrategy: CustomPreloadingStrategyService, scrollPositionRestoration: "top"});
