import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Acl, Team } from '../training/models/model';

@Pipe({ name: 'getProgress' })
export class GetProgress implements PipeTransform {
  transform(progress: any): string {
    return progress.avance + "%";
  }
}

@Pipe({ name: 'urlImage' })
export class UrlImage implements PipeTransform {
  transform(item: any, type: number): string {


    let url = 'https://d3puay5pkxu9s4.cloudfront.net/Users/';
    if (type == 0) {
      // Formato normal

      if (item.sender.image.includes('platform-lookaside.fbsbx.com')) {
        url += 'default/small_imagen.jpg';
      } else {

        if (item.sender.fuente == 'facebook' || item.sender.fuente == 'google') {
          if (item.sender.image != '') {
            if (item.sender.image.substring(0, 4) == 'http') {
              url = item.sender.image;
            } else {
              url += item.sender.id + '/small_' + item.sender.image;
            }
          } else {
            url += 'default/small_imagen.jpg';
          }
        } else if (item.sender.image == '') {
          url += 'default/small_imagen.jpg';
        } else {
          url += item.sender.id + '/small_' + item.sender.image;
        }


      }

    } else if (type == 1) {
      // Formato mapeado
      if (item.associated.length === 1) {
        if (item.associated[0].user.user_fuente == 'facebook' || item.associated[0].user.user_fuente.S == 'google') {
          if (item.associated[0].user.user_image != '') {
            if (item.associated[0].user.user_image.substring(0, 4) == 'http') {
              url = item.associated[0].user.user_image;
            } else {
              url += item.associated[0].user.user_id + '/small_' + item.associated[0].user.user_image;
            }
          } else {
            url += 'default/small_imagen.jpg';
          }
        } else if (item.associated[0].user.user_image == '') {
          url += 'default/small_imagen.jpg';
        } else {
          url += item.associated[0].user.user_id + '/small_' + item.associated[0].user.user_image;
        }
      } else {
        if (item.conversation.image == '') {
          url += 'default/small_imagen.jpg';
        } else {
          url += item.conversation.id + '/Image/' + item.conversation.image;
        }
      }
    } else if (type == 2) {
      // Formato cursos
      if (item.User.fuente == 'facebook' || item.User.fuente == 'google') {
        if (item.User.image != '') {
          if (item.User.image.substring(0, 4) == 'http') {
            url = item.User.image;
          } else {
            url += item.User.id + '/small_' + item.User.image;
          }
        } else {
          url += 'default/small_imagen.jpg';
        }
      } else if (item.User.image == '') {
        url += 'default/small_imagen.jpg';
      } else {
        url += item.User.id + '/small_' + item.User.image;
      }
    } else if (type == 3) {
      // Formato mapeado lambda 
      if (item.associated.length > 0) {
        if (item.associated[0].user.user_fuente == 'facebook' || item.associated[0].user.user_fuente == 'google') {
          if (item.associated[0].user.user_image != '') {
            if (item.associated[0].user.user_image.substring(0, 4) == 'http') {
              url = item.associated[0].user.user_image;
            } else {
              url += item.associated[0].user.user_id + '/small_' + item.associated[0].user.user_image;
            }
          } else {
            url += 'default/small_imagen.jpg';
          }
        } else if (item.associated[0].user.user_image == '') {
          url += 'default/small_imagen.jpg';
        } else {
          url += item.associated[0].user.user_id + '/small_' + item.associated[0].user.user_image;
        }
      } else {
        if (item.conversation.image == '') {
          url += 'default/small_imagen.jpg';
        } else {
          url += item.conversation.image ? item.conversation.id + '/Image/' + item.conversation.image : 'default/small_imagen.jpg';
        }
      }
    }
    else if (type == 4) {
      // Usuario de chat
      if (item.user) {
        if (item.user.user_fuente == 'facebook' || item.user.user_fuente == 'google') {
          if (item.user.user_image != '') {
            if (item.user.user_image.substring(0, 4) == 'http') {
              url = item.user.user_image;
            } else if (item.user.user_image.includes('data')) {
              url += 'default/small_imagen.jpg';
            } else {
              url += item.user.user_id + '/small_' + item.user.user_image;
            }
          } else {
            url += 'default/small_imagen.jpg';
          }
        } else if (item.user.user_image == '') {
          url += 'default/small_imagen.jpg';
        } else {
          url += item.user.user_id + '/small_' + item.user.user_image;
        }
      } else {

        url += 'default/small_imagen.jpg';

      }
    }
    else if (type == 5) {
      // Usuario de chat
      if (item.User && item.User.hasOwnProperty('image')) {

        if (item.User.image.substring(0, 4) == 'http') {
          url = item.User.image;
        } else if (item.User.image.includes('data')) {
          url += 'default/small_imagen.jpg';
        }
        else {

          if (item.User.image == '') {
            if (item.User.role == 'propietario') {
              url += 'professor/small_imagen.jpg';
              // if (item.user.user_image.substring(0, 4) == 'http') {              
              //   url += item.user.user_id + '/small_' + item.user.user_image;
              // }
            } else {
              url += 'default/small_imagen.jpg';
            }
          } else {
            url += item.User.id + '/small_' + item.User.image;
          }
        }

      } else {

        url += 'default/small_imagen.jpg';

      }


      // https://d3puay5pkxu9s4.cloudfront.net/Users/4859030/small_{"data":{"height":50,"is_silhouette":false,"url":"https://platform-lookaside.fbsbx.com/platform/prof
    }
    return url;
  }
}

@Pipe({ name: 'roleName' })
export class RoleName implements PipeTransform {
  transform(role: string): string {
    switch (role.toLocaleLowerCase()) {
      case "free":
        return "Estudiante libre";
      case "premium":
        return "Estudiante oficial";
      case "propietario":
      case "professor":
        return "Profesor";
      case "moderador":
      case "monitor":
        return "Monitor";
      case "administrator":
        return "Administrador";
      case "assistant":
      case "assistant_professor":
        return "Profesor asistente";
      default:
        return role
    }
  }
}

@Pipe({ name: 'userClassName' })
export class UserClassName implements PipeTransform {
  transform(role: string): string {
    switch (role.toLocaleLowerCase()) {
      case "free":
        return "student-free";
      case "premium":
        return "student-official";
      case "monitor":
        return "monitor";
      case "propietario":
      case "professor":
        return "teacher";
      case "moderador":
      case "assistant":
      case "administrator":
      case "assistant_professor":
        return "moderador";
    }
  }
}


@Pipe({ name: 'cutUserName' })
export class CutUserNamePipe implements PipeTransform {
  transform(username: string, length: string): string {

    username = username.toLocaleLowerCase();

    let split_username_length = username.trim().split(/\s+/).length;
    var un = username.split(' ');
    switch (length) {
      case '1':
        var user_name = un[0];
        break;
      case '2':
        var user_name = split_username_length < 3 ? username : split_username_length == 3 ? (un[0] + ' ' + un[1]) : (un[0] + ' ' + un[2]);
        break;
    }
    let name = '';

    user_name.split(' ').forEach(U => {
      name += (U.charAt(0).toUpperCase() + U.slice(1)) + ' ';
    });

    return name;

  }
}

@Pipe({ name: 'shoppingCarType' })
export class shoppingCarType implements PipeTransform {
  transform(type: number): string {
    switch (type) {
      case 1:
        return 'Certificado de estudios';
      case 2:
        return 'Carrera online';
    }
  }
}

@Pipe({ name: 'notificationsDate' })
export class notificationsDate implements PipeTransform {
  transform(_date: string): string {
    var today: any = new Date();
    var yesterday = "";
    var dd = String(today.getDate()).padStart(2, '0');
    var dd1 = String(today.getDate() - 1).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;
    yesterday = yyyy + '-' + mm + '-' + dd1;

    if (_date.substring(0, 10) == today) {
      return 'Hoy';
    } else if (_date.substring(0, 10) == yesterday) {
      return 'Ayer';
    } else {
      var monthNames = [
        "Ene", "Feb", "Mar",
        "Abr", "May", "Jun", "Jul",
        "Ago", "Sep", "Oct",
        "Nov", "Dic"
      ];
      return parseInt(_date.substring(8, 10)) + ' ' + monthNames[parseInt(_date.substring(5, 7)) - 1];
    }
  }
}

@Pipe({ name: 'messageDate' })
export class messageDate implements PipeTransform {
  transform(_date: string): string {
    var today: any = new Date();
    var yesterday = "";
    var dd = String(today.getDate()).padStart(2, '0');
    var dd1 = String(today.getDate() - 1).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;
    yesterday = yyyy + '-' + mm + '-' + dd1;


    if (_date.substring(0, 10) == today) {
      return 'Hoy';
    } else if (_date.substring(0, 10) == yesterday) {
      return 'Ayer';
    } else {
      var monthNames = [
        "Ene", "Feb", "Mar",
        "Abr", "May", "Jun", "Jul",
        "Ago", "Sep", "Oct",
        "Nov", "Dic"
      ];

      return parseInt(_date.substring(8, 10)) + ' ' + monthNames[parseInt(_date.substring(5, 7)) - 1];
    }
  }
}

@Pipe({ name: 'categoryName' })
export class categoryName implements PipeTransform {
  transform(categoryid: any, categories: any): string {
    if (categoryid && categories) {
      let result = categories.find(category => category.id == categoryid);
      return result ? result.name : '';
    }
    return '';
  }
}

@Pipe({ name: 'cssChildHeight' })
export class CssChildHeight implements PipeTransform {
  transform(elemt: any): number {
    const child = elemt.children[0];
    return parseFloat(window.getComputedStyle(child).getPropertyValue('height'));
  }
}


@Pipe({ name: 'courseConfigUrl' })
export class CourseConfigUrl implements PipeTransform {
  transform(course: any, user_id): string {
    let config = {
      curso_id: course.id,
      certification_id: course.certification_id,
      type: course.tipo,
      clase_id: course.clase_id != '' ? course.clase_id : 0,
      idu: user_id,
      source_id: course.source_id != '' ? course.source_id : 0,
      quiz_id: course.quiz_id != '' ? course.quiz_id : 0,
      language_id: course.language_id,
      state: course.certification_state,
      tour: course.certification_tour != null ? course.certification_tour : '-1'
    }

    // let url = 'https://apptemp.edutin.com/classroom?config=' + JSON.stringify(config)
    let url = 'https://app.edutin.com/classroom?config=' + JSON.stringify(config)

    return url;
  }
}




@Pipe({ name: 'groupNamePipe' })
export class GroupNamePipe implements PipeTransform {
  transform(conversation): string {

    let name = '';

    const user_name = (user) => {
      user = user.split(' ')[0];
      return user.charAt(0).toUpperCase() + user.slice(1);
    };


    if (conversation && conversation.associated.length == 1) {

      name = conversation.associated[0].user.user_name;
    } else if (conversation && conversation.associated.length > 0) {
      if (conversation.associated.length === 2) {
        name = user_name(conversation.associated[0].user.user_name) + " y " + user_name(conversation.associated[1].user.user_name);
      } else {
        name = user_name(conversation.associated[0].user.user_name) + ", " + user_name(conversation.associated[1].user.user_name) + " y " + (conversation.associated.length - 2).toString() + " más";
      }
    }

    return name;
  }
}

@Pipe({
  name: "checkPermissions",
})
export class CheckPermissionsPipe implements PipeTransform {
  transform(userId: string, userAcls: Acl, teams: Team[], id?: string, type?: string): boolean {
    // Obtener la lista de equipos
    // Verificar el ACL de cada equipo en la lista
    for (const team of teams) {
      if (team.users.some(user => user.userId === userId)) {
        if (team.acl) {
          if (team.acl.type === "FULL") {
            return true;
          } else if (team.acl.type === "LIMITED") {
            if (team.acl.permitted[type]?.includes(id)) {
              return true;
            }
          }
        }
      }
    }

    // Verificar el ACL del usuario
    if (userAcls) {
      if (userAcls.type === "FULL") {
        return true;
      } else if (userAcls.type === "LIMITED") {
        if (userAcls.permitted[type]?.includes(id)) {
          return true;
        }
      }
    }

    return false;
  }
}
