import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { AppService } from "src/app/app.service";

@Injectable({
  providedIn: "root",
})
export class TrainingGuard  {
  constructor(private _appService: AppService, private _router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const { valid, session, print } = this._appService.getUserData();
    // Cognito User
    if(next.params.hasOwnProperty("id")){
      let info = parseInt(next.params.id);
      sessionStorage.setItem("infoBusiness", JSON.stringify(info));
    }

    if (valid) {
      // User with session
      if (session == 1) {
        // Go
        return true;
      }
    }
    window.location.href = "https://app.edutin.com/user/login";
    return false
  }
}
