<div class="content-academy">
  <div class="alert" *ngIf="_appService.display_verification_alert && !user_routes.includes(_appService.url_route)">
    <div>
      <p>Es necesario <a (click)="verifyAuthenticatedUserEmail()">verificar su correo electrónico</a>
      </p>
    </div>

    <span class="icon-close" (click)="_appService.display_verification_alert=false"></span>
  </div>

  <div id="main-scroll" class="app-content">
    <nav-bar  [ngClass]="{'display__nav-bar': _appService.display_navbar}">
    </nav-bar>

    <div class="app-container"><router-outlet></router-outlet></div>
    
    <footer *ngIf="_appService.loadFooter"></footer>

    <wt-lazy [location]="commentsModalComponent" *ngIf="_appService.comment_modal_.state"></wt-lazy>
  </div>

</div>
