import { Injectable } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map, retry, tap } from "rxjs/operators";
import { AppService } from "../app.service";
import * as countries_data from "../../assets/countries.json";
import { TransformRoutePipe } from "./pipes/routes.pipes";
import {
  TransformBusinessPipe,
  TransformUserPipe,
  TransformUsersPipe,
} from "./pipes/business.pipe";
import { Business, Route, User } from "./models/model";
import { stateRequest } from "./models/enum";


@Injectable({
  providedIn: "root",
})
export class TrainingService {
  public hasTabs: Boolean = false;
  public showModal: Boolean = false;
  public showFilterModal: Boolean = false;
  public showModalGroup: Boolean = false;
  public showModalGroupStudents: Boolean = false;
  public courseModal: any = { id: 0, name: 0 };
  public hiddenModal: Boolean = false;
  public hiddenFilterModal: Boolean = false;
  public hiddenModalGroup: Boolean = false;
  public hiddenModalGroupStudents: Boolean = false;
  public showLateralMenu: Boolean = false;

  public loading: Boolean = true;

  // Se le concede permiso a la empresa logueada
  public permission: Boolean = false;

  conversations: Array<any> = [];

  baseLambdaUrl = "https://api.edutin.com/b/d";
  baseLambdaTrainingUrl = "https://api.edutin.com/tr/d";
  public token: string;

  list_users: Array<any> = [];
  list_groups: Array<any> = [];

  user_asign: Array<any> = [];
  group_asign: Array<any> = [];

  list_asign_courses: Array<any> = [];
  list_asign_groups: Array<any> = [];
  set_list_asign: boolean;

  show_group_chat: boolean = false;

  baseRetry: number = 2;
  isOwner: boolean = false;
  constructor(
    private http: HttpClient,
    private _appService: AppService,
  ) {
  }

  getHeaders(): any {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + this._appService.token,
    });

    return { headers: headers };
  }

  post(url: any, formData: FormData) {
    return this.http.post(url, formData, this.getHeaders());
  }

  put(url: any, formData: FormData) {
    return this.http.put(url, formData, this.getHeaders());
  }

  delete(url: any) {
    return this.http.delete(url, this.getHeaders());
  }

  /**
   * -1: No ha tenido respuesta de la busqueda de la petición
   * 0: No tiene empresa
   * 1: Tiene empresa
   */
  public stateGetBusiness: number = stateRequest.notExcuted;
  public businessInfo: Business[] = [];
  public _infoBusinessObservable: Subject<Business[]> = new Subject<
    Business[]
  >();
  public loadingBusiness: Boolean = false;
  public business: Business;

  getBusiness(id?: any): Observable<any> {
    console.log("getBusiness");
    this.loadingBusiness = true;
    const url = id
      ? `${this.baseLambdaUrl}/business/"${id}"`
      : `${this.baseLambdaUrl}/business`;
    return this.http.get(url, this.getHeaders()).pipe(
      map((response: any) => {
        const { status, data } = response;
        const transformBusinessPipe = new TransformBusinessPipe();
        this.stateGetBusiness = status
          ? stateRequest.excuted
          : stateRequest.error;
        this.loadingBusiness = false;
        this.businessInfo = status ? transformBusinessPipe.transform(data) : [];
        this._infoBusinessObservable.next(this.businessInfo);
        return { valid: status, print: this.businessInfo };
      }),
      tap((response) => {
        if (id && response.valid) {
          this.business = this.businessInfo.find(
            (business) => business.id == id
          );
          this.isOwner = this._appService.user_data.id == this.business.owner_id;
        }
      }),
      retry(this.baseRetry),
      catchError(this.handleError<any>("getBusiness", []))
    );
  }

  updateBusiness(id: any, data: any): Observable<any> {
    const url = `${this.baseLambdaUrl}/business/"${id}"`;
    return this.http.put(url, data, this.getHeaders()).pipe(
      map((response: any) => {
        return { valid: response.status, print: response.data };
      }),
      retry(this.baseRetry),
      catchError(this.handleError<any>("putBusiness", []))
    );
  }

  createBusiness(data: any): Observable<any> {
    const url = `${this.baseLambdaUrl}/business`;
    return this.http.post(url, data, this.getHeaders()).pipe(
      map((response: any) => {
        return { valid: response.status, print: response.data };
      }),
      retry(this.baseRetry),
      catchError(this.handleError<any>("postBusiness", []))
    );
  }

  cleanBusiness() {
    this.business = null;
    this.businessInfo = [];
    this._infoBusinessObservable.next(this.businessInfo);
    this.stateGetBusiness = stateRequest.notExcuted;
    this.routesInfo = [];
    this._infoRoutesObservable.next(this.routesInfo);
    this.stateGetRoutes = stateRequest.notExcuted;
    this.usersInfo = [];
    this._infoUsersObservable.next(this.usersInfo);
    this.stateGetUsers = stateRequest.notExcuted;
    this.isOwner = false;
  }

  // /business/{business_id}/file/{key}
  deleteBusinessFile(
    businessId: string,
    fileName: string,
    id?: string
  ): Observable<any> {
    const url = !id
      ? `${this.baseLambdaUrl}/business/"${businessId}"/file/"${fileName}"`
      : `${this.baseLambdaUrl}/business/"${businessId}"/groups/"${id}"/file/"${fileName}"`;
    return this.http.delete(url, this.getHeaders()).pipe(
      map((response: any) => {
        return { valid: response.status, print: response.data };
      }),
      retry(this.baseRetry),
      catchError(this.handleError<any>("deleteBusinessFile", []))
    );
  }

  public stateGetRoutes: number = stateRequest.notExcuted;
  public routesInfo: Array<Route> = [];
  public _infoRoutesObservable: Subject<Route[]> = new Subject<Route[]>();
  public loadingRoutes: Boolean = false;
  public _infoRouteObservable: Subject<{ method: number; route: Route }> =
    new Subject<{ method: number; route: Route }>();

  getRoutes(id: any): Observable<any> {
    this.loadingRoutes = true;
    return this.http
      .get(`${this.baseLambdaUrl}/business/"${id}"/routes`, this.getHeaders())
      .pipe(
        map((response: any) => {
          const { status, data } = response;
          this.stateGetRoutes = status
            ? stateRequest.excuted
            : stateRequest.error;
          if (status) {
            const routesPipe = new TransformRoutePipe();
            const tempRoutes: any = routesPipe.transform(data, true);
            this.routesInfo = tempRoutes;
            this._infoRoutesObservable.next(this.routesInfo);
          }
          this.loadingRoutes = false;
          return { valid: status, print: this.routesInfo };
        }),
        retry(this.baseRetry),
        catchError(this.handleError<any>("getRoutes", []))
      );
  }

  createRoute(businessId: string, data: any): Observable<any> {
    const url = `${this.baseLambdaUrl}/business/"${businessId}"/routes`;
    return this.http.post(url, data, this.getHeaders()).pipe(
      map((response: any) => {
        const { status, data } = response;
        if (status) {
          const RoutePipe = new TransformRoutePipe();
          const tempRoute = RoutePipe.transform(data, false);
          return { valid: status, print: tempRoute };
        }
        return { valid: status, print: data };
      }),
      retry(this.baseRetry),
      catchError(this.handleError<any>("postRoute", []))
    );
  }

  deleteRoute(businessId: string, route: Route): Observable<any> {
    const url = `${this.baseLambdaUrl}/business/"${businessId}"/routes/"${route.id}"`;
    return this.http.delete(url, this.getHeaders()).pipe(
      map((response: any) => {
        const { status, data } = response;
        if (status) {
          return { valid: status, print: route };
        }
        return { valid: status, print: data };
      }),
      retry(this.baseRetry),
      catchError(this.handleError<any>("deleteRoute", []))
    );
  }

  updateRoute(businessId: string, id: string, data: any): Observable<any> {
    const url = `${this.baseLambdaUrl}/business/"${businessId}"/routes/"${id}"`;
    return this.http.put(url, data, this.getHeaders()).pipe(
      map((response: any) => {
        return { valid: response.status, print: response.data };
      }),
      retry(this.baseRetry),
      catchError(this.handleError<any>("putRoute", []))
    );
  }

  public stateGetUsers: number = stateRequest.notExcuted;
  public usersInfo: Array<any> = [];
  public _infoUsersObservable: Subject<any[]> = new Subject<any[]>();
  public loadingUsers: Boolean = false;
  public _infoUserObservable: Subject<{ method: number; user: any }> =
    new Subject<{ method: number; user: any }>();

  getUsers(id: any): Observable<any> {
    this.loadingUsers = true;
    return this.http
      .get(`${this.baseLambdaUrl}/business/"${id}"/users`, this.getHeaders())
      .pipe(
        map((response: any) => {
          const { status, data } = response;
          this.stateGetUsers = status
            ? stateRequest.excuted
            : stateRequest.error;
          if (status) {
            const usersPipe = new TransformUsersPipe();
            const tempUsers = usersPipe.transform(data);
            this.usersInfo = tempUsers;
            this._infoUsersObservable.next(this.usersInfo);
          }
          this.loadingUsers = false;
          return { valid: status, print: this.usersInfo };
        }),
        retry(this.baseRetry),
        catchError(this.handleError<any>("getBusiness", []))
      );
  }

  addUsersBusiness(businessId: string, data: any): Observable<any> {
    const url = `${this.baseLambdaUrl}/business/"${businessId}"/users`;
    return this.http.post(url, data, this.getHeaders()).pipe(
      map((response: any) => {
        const { status, data } = response;
        if (status) {
          const usersPipe = new TransformUserPipe();
          let tempUsers = [];
          data.forEach((user) => {
            if (user.status) {
              const tempUser = usersPipe.transform(user.data);
              tempUsers.push(tempUser);
            }
          });
          return { valid: status, print: tempUsers };
        }
        return { valid: response.status, print: response.data };
      }),
      retry(this.baseRetry),
      catchError(this.handleError<any>("addUserToBusiness", []))
    );
  }

  deleteUsersBusiness(businessId: string, user: User): Observable<any> {
    const url = `${this.baseLambdaUrl}/business/"${businessId}"/users/"${user.id}"`;
    return this.http.delete(url, this.getHeaders()).pipe(
      map((response: any) => {
        const { status, data } = response;
        return { valid: status, print: user };
      }),
      retry(this.baseRetry),
      catchError(this.handleError<any>("deleteUserBusiness", []))
    );
  }

  public recommendedInfo: Array<any> = [];
  public loadingRecommended: Boolean = false;
  getRecommendedRelations(category_id): Observable<any> {
    return this.http
      .get(
        this.baseLambdaUrl + "/categories/recommended/" + category_id,
        this.getHeaders()
      )
      .pipe(
        map((response: any) => {
          if (response.status) {
            this.recommendedInfo = response.data;
            //console.log("Training Service: this.recommendedRelations ", this.recommendedRelations);
            return { valid: true, print: response.data };
          } else {
            return { valid: false, print: response.data };
          }
        }),
        retry(this.baseRetry),
        catchError(this.handleError<any>("getRecommendedRelations", []))
      );
  }

  public progressInfoAll: Array<any> = [];
  public progress: Boolean = false;
  getProgressAll(): Observable<any> {
    return this.http
      .get(this.baseLambdaUrl + "/certifications/progress", this.getHeaders())
      .pipe(
        map((response: any) => {
          if (response.status) {
            this.progress = true;
            this.progressInfoAll = response.data[0];
            //console.log("Training Service: getProgressAll - progressInfoAll ", this.progressInfoAll)
            return { valid: true, print: response.data[0] };
          } else {
            return { valid: false, print: response.data };
          }
        }),
        retry(this.baseRetry),
        catchError(this.handleError<any>("getProgressAll", []))
      );
  }

  public progressInfoUsers: Array<any> = [];
  getProgressUser(user_id): Observable<any> {
    return this.http
      .get(
        this.baseLambdaUrl + "/certifications/progress?user_id=" + user_id,
        this.getHeaders()
      )
      .pipe(
        map((response: any) => {
          if (response.status) {
            this.progressInfoUsers.push(response.data[0]);
            //console.log("Training Service: getProgressUser - progressInfoUsers: ", this.progressInfoUsers)
            return { valid: true, print: response.data };
          } else {
            return { valid: false, print: response.data };
          }
        }),
        retry(this.baseRetry),
        catchError(this.handleError<any>("getProgressUser", []))
      );
  }

  public progressInfoGroups: Array<any> = [];
  getProgressGroups(group_id): Observable<any> {
    return this.http
      .get(
        this.baseLambdaUrl + "/teams/" + group_id + "/certifications/progress",
        this.getHeaders()
      )
      .pipe(
        map((response: any) => {
          if (response.status) {
            this.progressInfoGroups.push(response.data[0]);
            //console.log("Training Service: getProgressGroups - progressInfoGroups: ", this.progressInfoGroups)
            return { valid: true, print: response.data };
          } else {
            return { valid: false, print: response.data };
          }
        }),
        retry(this.baseRetry),
        catchError(this.handleError<any>("getProgressGroups", []))
      );
  }

  public getCertifications(): Observable<any> {
    return this.http
      .get(this.baseLambdaTrainingUrl + "/certifications", this.getHeaders())
      .pipe(
        map((response: any) => {
          //console.log("response getCertifications - traininfService", response)
          if (response.status) {
            return { valid: true, print: response.data[0] };
          } else {
            return { valid: false, print: response };
          }
        }),
        retry(this.baseRetry),
        catchError(this.handleError<any>("getCertification", []))
      );
  }

  public getCountries() {
    let countries: any = (countries_data as any).default;
    countries.shift(); //Eliminar el primer (internacional)
    let countries_data_response = [];
    let array_ = [];
    countries.forEach((element, index) => {
      if (index == countries.length - 1) {
        countries_data_response = countries.concat(array_);
        countries.sort((a: any, b: any) => {
          let countryA = a.id;
          let countryB = b.id;
          return countryA < countryB ? -1 : countryA > countryB ? 1 : 0;
        });
      }
    });
    return countries_data_response;
  }

  // POSTS
  teamAdd(data): Observable<any> {
    return this.post(this.baseLambdaUrl + "/teams", data).pipe(
      map((response: any) => {
        if (response.status) {
          return { valid: true, print: response.data };
        } else {
          return { valid: false, print: response.data };
        }
      })
    );
  }

  courseAdd(data): Observable<any> {
    return this.post(this.baseLambdaUrl + "/business/cursos", data).pipe(
      map((response: any) => {
        if (response.status) {
          return { valid: true, print: response.data };
        } else {
          return { valid: false, print: response };
        }
      })
    );
  }

  userAdd(data): Observable<any> {
    return this.post(this.baseLambdaUrl + "/students", data).pipe(
      map((response: any) => {
        if (response.status) {
          return { valid: true, print: response.data };
        } else {
          return { valid: false, print: response };
        }
      })
    );
  }

  businessAdd(data): Observable<any> {
    return this.post(this.baseLambdaUrl + "/business", data).pipe(
      map((response: any) => {
        if (response.status) {
          return { valid: true, print: response.data };
        } else {
          return { valid: false, print: response.data };
        }
      })
    );
  }

  // enrollUserTraining(data): Observable<any> {
  //   return this.post(
  //     this.baseLambdaUrl + "/business/certifications", data).pipe(
  //     map((response: any) => {
  //       if (response.status) {
  //         this.getUsers().subscribe();
  //         //console.log("TrainingService - enrollUserTraining: response.data ", response.data);
  //         return { valid: true, print: response.data };
  //       } else {
  //         return { valid: false, print: response };
  //       }
  //     })
  //   );
  // }

  conversationEdit(user_id, data, group_id): Observable<any> {
    return this.put(
      this.baseLambdaUrl + "/conversations/" + user_id,
      data
    ).pipe(
      map((response: any) => {
        if (response.status) {
          return { valid: true, print: response.data };
        } else {
          return { valid: false, print: response };
        }
      }),
      retry(this.baseRetry),
      catchError(this.handleError<any>("conversationEdit", []))
    );
  }

  certificationEdit(id, data): Observable<any> {
    //console.log("link: ", this.baseLambdaTrainingUrl + "/certifications" + (id ? "/" + id : ""));
    //console.log("data", data);
    return this.put(
      this.baseLambdaTrainingUrl + "/certifications" + (id ? "/" + id : ""),
      data
    ).pipe(
      map((response: any) => {
        if (response.status) {
          return { valid: true, print: response.data };
        } else {
          return { valid: false, print: response.errorMessage };
        }
      }),
      retry(this.baseRetry),
      catchError(this.handleError<any>("certificationEdit", []))
    );
  }

  businessEdit(id, data): Observable<any> {
    //console.log("Training Service: businessEdit - id ", id);
    //console.log("Training Service: businessEdit - data ", data);
    return this.put(this.baseLambdaUrl + "/business/" + id, data).pipe(
      map((response: any) => {
        if (response.status) {
          return { valid: true, print: response.data };
        } else {
          return { valid: false, print: response.errorMessage };
        }
      }),
      retry(this.baseRetry),
      catchError(this.handleError<any>("TeamEdit", []))
    );
  }

  // DELETES
  userDelete(user_id): Observable<any> {
    return this.delete(this.baseLambdaUrl + "/students/" + user_id).pipe(
      map((response: any) => {
        // Validar datos
        if (response.status) {
          return { valid: true, print: response.Message };
        } else {
          return { valid: false, print: response.errorMessage };
        }
      }),
      retry(this.baseRetry),
      catchError(this.handleError<any>("UserDelete", []))
    );
  }

  teamDelete(id): Observable<any> {
    return this.delete(this.baseLambdaUrl + "/teams/" + id).pipe(
      map((response: any) => {
        // Validar datos
        if (response.status) {
          return { valid: true, print: response.Message };
        } else {
          return { valid: false, print: response.errorMessage };
        }
      }),
      retry(this.baseRetry),
      catchError(this.handleError<any>("TeamDelete", []))
    );
  }

  courseDelete(id): Observable<any> {
    return this.delete(this.baseLambdaUrl + "/courses/" + id).pipe(
      map((response: any) => {
        // Validar datos
        if (response.status) {
          return { valid: true, print: response.Message };
        } else {
          return { valid: false, print: response.errorMessage };
        }
      }),
      retry(this.baseRetry),
      catchError(this.handleError<any>("CourseDelete", []))
    );
  }

  public certifications() {
    this.getCertifications().subscribe((certifications) => {
      if (certifications.valid) {
        this.usersInfo.forEach((user, index) => {
          Object.entries(certifications.print).forEach(
            ([user_id, certification]) => {
              //console.log("Training Service: getCertifications - user_id, certification", user_id, certification)
              if (user.User.id == user_id) {
                //console.log("Training Service: getCertifications - certification", certification);
                this.usersInfo[index].Study = certification;
              }
            }
          );
        });
        //console.log("Training Service: Usuarios con certificados: ", this.usersInfo);
      }
    });
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.log(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
