import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";


@Injectable()
export class CustomInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    // console.log("outgoing request", request);

    if (request.url.includes(environment.domain)) {
      request = request.clone({
        withCredentials: true
      });
    }

    // console.log("new outgoing request", request);

    return next.handle(request);
  }
}