import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { APP_ROUTING } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { AppService } from './app.service';
import { RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStategy } from './custom-route-reuse-strategy';
import { PushNotificationService } from './shared/service/push-notification.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomInterceptor } from './auth/services/cookies.interceptor';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    APP_ROUTING,
    HttpClientModule,
    SharedModule,
  ],
  providers: [AppService, {
    provide: RouteReuseStrategy,
    useClass: CustomRouteReuseStategy
  },
    PushNotificationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {

}
