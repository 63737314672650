import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Router,ActivatedRoute, NavigationEnd } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { TrainingService } from 'src/app/training/training.service';

@Component({
  selector: 'nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  @Output() open_live_chat: EventEmitter<any> = new EventEmitter<any>();
  showSearch: boolean = false;
  isRecruitment: boolean = true;
  showProfile: boolean = false;
  showMenu: boolean = false;

  constructor(
    public _router: Router,
    public _route: ActivatedRoute,
    public _appService: AppService,
    public _authService: AuthService,
    public _trainService: TrainingService,
  ) { }

  ngOnInit(): void {
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isRecruitment = !event.url.includes('/business');
      }
    });
  }

  goToApp(): void {
    if (this._appService.user_session != -1) {
      if (this._appService.user_session == 1) {
        if(this.isRecruitment){
          this._router.navigate(['/hr/']);
        }
        else{
          // if (this._appService.url_route != 'home') {
          const { print } = this._appService.getUserData();
          const { certification_id } = print;
          // User with certification
          if (certification_id && certification_id != '0' && certification_id != '-1') {
            this._router.navigate(['/home']);
          } else {
            this._router.navigate(['/category']);
          }
        }
      } else {
        if (this._appService.url_route != 'category') {
          this._router.navigate(['/category']);
        } else {
          window.location.href = 'https://edutin.com/';
        }
      }
    }
  }

  toggleProfile(): void {
    this.showProfile = !this.showProfile;
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this._appService.defaultGetsNavBar();
    this._authService.signOut().subscribe();
    this._appService.nextToken = null;
  }

  toggleMenu(): void {
    this.showMenu = !this.showMenu;
  }
}
