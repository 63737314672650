import { environment } from "src/environments/environment";

export const UrlLambdaApiBus: string= environment.urlAPITrain;
export const UrlLambdaApi: string= environment.urlAPI;
export const UrlLambdaProduction: string= environment.urlAPIProduction;
export const urlLambdaApiMktg: string = environment.urlAPIMktg;
export const urlLambdaApiAds: string = environment.urlAPIAds;
export const urlLambdaApiForms: string = environment.urlAPIForms;

export type UUID = string;
export type ApplicantType = 'recommended' | 'applicants' | 'candidates'
export interface Response {
  status: Boolean;
  data?: Array<any>;
  item?: Object;
  LastEvaluatedKey?:LastEvaluatedKey;
  error?: any
}

export interface Modals{
  inviteCandidate?:any,
  inviteMember?:any,
  userCommunity?:any,
  newStage?:any,
  mail?:any,
  remove?:any,
}

export interface LastEvaluatedKey{
  id:UUID;
  businessId:UUID;
}

export interface ProjectResponse extends Response{
  data? : VacanciesModel[]
}

export interface CreationResponse{
  status: Boolean;
  data?:any;
  item?: Object;
  error?: any;
}

export interface CreationProjectResponse extends CreationResponse{
  data?: any
}

export interface vacancyCreation {
  id?:UUID;
  departmentId?:UUID;
  dptBusinessId?:UUID;
  name?:string;
  priority?:string;
  ubication:string;
}

export interface VacanciesModel {
  id:UUID;
  businessId:number;
  departmentId:UUID;
  jobId?:UUID;
  name:string;
  favorite: boolean;
  countNew: number;
  countAnswereds: number;
  countContacteds: number;
  countCandidates: number;
  owner:User;
  lastActivity:string | Date;
  status:string;
  request?:string;
  spent?:string;
  views?:string;
  jobCard?:Advertisement;
  state?: string;
}

export interface QuestsModel{
  date:string | Date;
  userId:UUID;
  businessId:number;
  id:UUID;
  vacancyId:UUID;
  type:string;
  status:string;
  user:User;
}


export interface DepartmentModel {
  id:UUID;
  name:string;
  bussinessId:UUID;
}

export interface JobModel {
  id:UUID;
  name:string;
  bussinessId:UUID;
}

export interface Advertisement{
  description?:string;
  quiz?: string;
  aptitudes?:string[];
  priority?:string;
  ubication?:string;
  city?:string;
  modality?:string;
  jobType?:string;
  campaign?:Campaign;
  group?:Group;
}

export interface DataBusiness{
  id: string;
  name: string;
  email: string;
  ownerId: string;
  ownerName: string;
  logo?: string;
  description?: string;
  dateCreate?: string;
  rubro?: number;
  countryId?: string;
  hrStages:Stage[]
}
export interface Stage{
  id?:UUID;
  name?:string;
  notificationId?:string;
  order?:number,
  mailId?:UUID;
  value?:string;
}
export interface Mail{
  id?:UUID;
  subject?:string,
  content?:string,
  ownerId?:string,
  previewText?:string,
  state?:string,
  lastActivity?:string,
  source?:string,
  type?:string,
  createdBy?:string,
  name?:string,
  refusedCount?:string,
  stageId?:UUID,
}
export interface UpdateProject{
  id?:UUID;
  name?:string;
  favorite?: boolean;
  post?:string;
  status?:string;
  hasEmployee?:boolean,
  jobCard?:Advertisement;
}
export interface Applicants{
  id?: UUID,
  vacancyId?: UUID,
  recruiterId?:string,
  applicationDate?: Date,
  avatar?:string,
  quiz?: any[],
  state?: string,
  hv: string,
  status: string,
  tags?: any[],
  businessId?: UUID,
  countryInfo?:CountryInformation,
  user?: User,
  inscriptions?: Certification[],
  educations?: Educations[],
  experience?: Experience[],
  certifications?:Certification[],
  skills:Skills[],
  stageId?:string,
  recommendation?:boolean,
}
export interface Certification {
  id: UUID;
  userId: UUID;
  courseId: string;
  state: string;
  date: string;
  course?:Course;
}
export interface Course {
  certificationName:string,
  id: UUID;
  name:string,
  slug:string,
  students:number, 
  // date:string,
}

export interface qparamsApplicants{
  departmentId?:UUID,
  lastEvaluatedKey?: any,
  vacancyId?:UUID,
}

export interface Skills{
  id: UUID;
  userId:UUID;
  skillId:UUID;
  value:string;
  description:string;
  name:string;
  type:'habilidad' | 'idioma'
}

export interface User {
  userId: UUID;
  email: string;
  countryId?:string;
  userName: string;
  userImage: string;
  profession?: string;
}

export interface CountryInformation {
  id: number;
  code: string;
  currency: string;
  name: string;
  shortName: string;
  flag?: string;
}

export interface Educations{
  id: UUID,
  userId?: string, // ID del usuario
  career: string, // Nombre del la carrera
  degreeLevel: string,  // Nivel de estudio
  curriculumId?: string, // Id del curriculum
  end?: string, // Fecha de finalización
  city?: string,   // Ciudad donde desempeño el cargo
  country: string,  // País donde desempeñó el cargo
  description?: Array<any>,  // Funcionees que desarrollo en el cargo
  start: string,  // Fecha de inicio
  current?: boolean,
  type?: string,  // Tipo de estudio que desempeñó 0=ACADEMIC - 1=CERTIFICATION 2=AWARD
  institution: string, // Universidad donde estudió
  fieldStudy?: string,
  logoUrl?: string,
  url?: string,  // Enlace del certificado
}

export interface Experience {
  id: UUID,
  userId?: string, // ID del usuario
  position: string, // Nombre del cargo
  curriculumId?: string, // Id del curriculum
  end?: string, // Fecha de finalización
  city?: string,   // Ciudad donde desempeño el cargo
  country: string,  // País donde desempeñó el cargo
  functions?: Array<any>,  // Funcionees que desarrollo en el cargo
  start: string,  // Fecha de inicio
  current?: boolean, // Si es el actual trabajo
  type?: string  // Tipo de trabajo que desempeñó
  company: string // Empresa donde desempeñó el cargo
}

export interface Notes{
  applicationId?:string,
  id?: UUID,
  note ?: string,
  privacy?: "PUBLIC" | "PRIVATE" | "ONLY_ME",
  created?: Date,
  lastEdited?: Date,
  collaborators?:[],
  creatorId?:string
}

// Define el tipo PermitionType
export type PermitionType = 'full' | 'view' | 'limited';

export interface updateNote{
  id?: UUID,
  note ?: string,
  lastEdited?: Date,
  collaborators?:[]
}

export interface CreationNotesResponse extends CreationResponse{
  data?: Notes;
}

export interface collaborators{
  userId: UUID,
  name: string,
}

export interface CreationVacancieResponse extends CreationResponse{
  data?: any
}

export interface candidateFilterModel{
  applicantType?: ApplicantType,
  stageId?:string,
  state?:string,
  status?:string,
  ubications?:string[],
  educations?:string[],
  courses?:string[],
  charges?:string[],
  certifications?:string[],
  languages?:string[],
  skills?:string[]
}


export interface qparamsVacancy {
  id?: string,
  business_id?: string,
  favorite?: boolean,
  invited?: boolean,
  status?: string,
  LastEvaluatedKey?: any,
  pages?: boolean,
  seek?: string,
}

export interface AlertProps{
  message: string,
  type: 'error' | 'accept',
  time?: number
}


export interface Campaign{
  category:string,
  id?: UUID,
  created?: Date,
  name?: string,
  objective?:string,
  ownerId?: string,
  status?:string;
}

export interface Group{
  id?: UUID,
  name?: string,
  campaignId?: UUID,
  amountSpend:string,
  created?: Date,
  diviceLocations:string[],
  viewsLocations:string[],
}

export interface Forms{
  id: UUID,
  attemptsAllowed?:string,
  canBack:boolean,
  countQuestions:string,
  countSections:string,
  createdAt?: Date,
  deadline:string,
  finalMessage:string,
  isQuiz:boolean,
  orderRandom:boolean,
  ownerId: string,
  parentId: string,
  privacy: string,
  sections:SectionForms,
  theme:{},
  title:string,
  type:string,
  url:string,
}

export interface SectionForms{
  count:number,
  items:{
    id: UUID,
    description:string,
    formId: UUID,
    ownerId: string,
    order: string,
    title:string,
    questions:Questions
  }
}

export interface Questions{
  id: UUID,
  answers:string[],
  feedback:string,
  formId: UUID,
  media: {},
  options:string[],
  order: string,
  required:boolean,
  sectionId:string,
  title:string,
  type:string,
}






