import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { UserData } from './auth.models';
import { IndexedDBService } from './indexedDB.service';
import { environment } from 'src/environments/environment';
import { UrlImage } from 'src/app/shared/shared.pipe';

Auth.configure({
  authenticationFlowType: 'USER_SRP_AUTH',
});


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public siteKey = "6LfeMIwoAAAAAD9buoCeI6jaiY_6K7CrI5ZueBpY"; //key para captcha de google Invisible
  public passwordConfirmRegistration: string;

  public emailChangePass = {
    detail: null,
    type: null
  };

  public resetPasswordRequired: boolean = false;

  public newPasswordRequired = {
    email: '',
    user: null,
    requiredAttributes: null,
  }

  public userInRoute: any;
  public userLogged: any;
  public countries: any;
  public countries_copy: any;
  public userData: UserData;
  public user_country_id = undefined;
  public user_ip = undefined;
  public userCountry: any = {
    code: "1",
    id: 230,
    name: "ESTADOS UNIDOS",
    short_name: "US"
  }

  public dataLogin = {
    email: '',
    password: '',
    country: null
  }

  constructor(
    public router: Router,
    private http: HttpClient,
    private _appService: AppService,
    private _indexedDBService: IndexedDBService,
  ) {
    this._indexedDBService.openIndexedDB().subscribe(opendb => { });
  }

  getUserIndexedDB = (user_id) => new Observable((observer) => {
    this._indexedDBService.getUser(user_id).subscribe((user: any) => {
      observer.next(user);
    });
  });

  updateUserIndexedDB = (new_user) => new Observable((observer) => {
    this._indexedDBService.updateUser(new_user).subscribe(resp => {
      observer.next();
    });
  });

  public classroom = '/classroom?config=%7B"certification_id":"499896","clase_id":"409342484","curso_id":"2631","idu":"239965","language_id":"2","quiz_id":"1606","source_id":"0","state":"-1","status":true,"tour":"4","type":"2"%7D';

  public token;

  isUserAuthenticated(): Observable<any> {
    return from(Auth.currentAuthenticatedUser()
      .then(user => {

        this.userLogged = JSON.parse(JSON.stringify(user));
        this.token = this.userLogged.signInUserSession.idToken.jwtToken;
        const cognitoUser = JSON.parse(JSON.stringify(user));

        this.currentSession().subscribe((session: any) => {
          let { currentSession, status } = session;

          switch (status) {
            case 'ok':
              //Usuario está autenticado
              const { signInUserSession } = cognitoUser;

              if (signInUserSession != null && (signInUserSession.idToken.payload.fuente != 'google' && signInUserSession.idToken.payload.fuente != 'facebook')) {
                user.refreshSession(currentSession.refreshToken, (err, session) => {
                  //('session', err, session);
                  // const { idToken, refreshToken, accessToken } = session;
                  if (session && session.idToken != null && session.idToken.payload != null) {
                    session.idToken.payload.picture = new UrlImage().transform({ User: session.idToken.payload }, 5);
                    this._appService.user_data = JSON.parse(JSON.stringify(session.idToken.payload));
                  }
                });
              }
              this._appService.setUserData(cognitoUser);

              break;
            case 'error':
              this._appService.cognitoUserAuthenticated = [];
              this._appService.setUserData([]);
              break;
          }
        })
      })
      .catch(error => {
        this._appService.cognitoUserAuthenticated = [];
        this._appService.setUserData([]);
      })
    );
  }

  currentSession(): Observable<any> {
    return from(Auth.currentSession()
      .then(currentSession => {
        return { status: 'ok', currentSession };
      })
      .catch(error => {
        return { status: 'error', error };
      })
    )
  }

  signOut = (condition?) => new Observable((observer) => {
    this._appService.loadNavBar = this._appService.loadFooter = false;

    Auth.signOut().then(() => {
      this.logOutLearn(condition).subscribe(resp => {

        this._appService.cognitoUserAuthenticated = null;
        this._appService.token = null;

        this.clearAllCookies();//nos aseguramos de limpiar todas las cookies al cerrar sesión

        setTimeout(() => {
          observer.next(true);
        }, 150);

      });

      this.userLogged = null;
      // localStorage.clear();

      var redirectItem = sessionStorage.getItem('redirect');

      sessionStorage.clear();

      if (redirectItem != undefined) sessionStorage.setItem('redirect', redirectItem);

    });

    this._appService.userSignUpEmail = null;
  });

  logOutLearn(redirect?): Observable<any> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this._appService.token
    });

    return this.http.get(`${environment.domain}/auths/logout`, { headers }).pipe(
      map((response: any) => {
        if (redirect == null) {
          this.router.navigate(['/user/accounts']);
        }
      }),
      catchError(error => {
        if (redirect == null) this.router.navigate(['/user/accounts']);
        return of(error)
      })
    );
  }

  async getHeaders() {
    const myInit = {
      headers: {
        Authorization: `Bearer ${this._appService.cognitoUserAuthenticated.signInUserSession.idToken.jwtToken}`
      },
    };

    return myInit
  }

  getHeadersLambda(): any {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this._appService.token
    })
    return ({ headers: headers });
  }

  public clearAllCookies() {
    const cookies = document.cookie.split(";");
    cookies.forEach(cookie => {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      const domain = environment.appDomain == "http://127.0.0.1:4200" ? '127.0.0.1' : ".edutin.com";
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain};`;
    })
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure

      console.log('%c error::', 'color:red', error); // log to console instead
      // (error.error); // log to console instead
      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
