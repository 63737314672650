import { Pipe, PipeTransform } from "@angular/core";
import { Acl, Business, Team, User } from "../models/model";

@Pipe({
  name: "transformBusinessPipe",
})
export class TransformBusinessPipe implements PipeTransform {
  transform(data: any[]): Business[] {
    const business: Business[] = [];
    data.forEach((data: any) => {
      const busi: Business = {
        id: data.id,
        name: data.name,
        email: data.email,
        owner_id: data.owner_id,
        logo: data.logo,
        description: data.description,
        date_create: data.date_create,
        owner_name: data.owner_name,
        rubro: data.rubro,
        country_id: data.country_id
      };

      business.push(busi);
    });

    return business;
  }
}

@Pipe({
  name: "transformUsersPipe",
})
export class TransformUsersPipe implements PipeTransform {
  transform(data: any[]): User[] {
    const users: User[] = [];
    data.forEach((data: any) => {
      const user: User = {
        id: data.id ? data.id : data.users_bussiness_id,
        name: data.info?.user_name,
        email: data.info?.email,
        userId: data.user_id,
        countryId: data.info?.country_id,
        date: data.date,
        businessId: data.business_id ? data.business_id : data.bussiness_id,
        image: data.info?.user_image,
      };

      users.push(user);
    });

    return users;
  }
}

@Pipe({
  name: "transformUserPipe",
})
export class TransformUserPipe implements PipeTransform {
  transform(data: any): User {
    const users: User = {
      id: data.id ? data.id : data.users_bussiness_id,
      name: data.info?.user_name,
      email: data.info?.email,
      userId: data.user_id,
      countryId: data.info?.country_id,
      date: data.info?.date,
      businessId: data.business_id ? data.business_id : data.bussiness_id,
      image: data.info?.user_image,
    };
    return users;
  }
}

@Pipe({
  name: "userInfoPipe",
})
export class UserInfoPipe implements PipeTransform {
  transform(incomplete: any[], complete: User[]): User[] {
    return incomplete.map((incompleteUser) => {
      const completeUser = complete.find(
        (user: User) => user.userId === incompleteUser.userId
      );
      return completeUser;
    });
  }
}

@Pipe({
  name: "secondsToTimePipe",
})
export class SecondsToTimePipe implements PipeTransform {
  transform(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - hours * 3600) / 60);

    // 21h 30min

    return hours + "h " + (minutes > 9 ? "" + minutes : "0" + minutes) + "min";
  }
}

@Pipe({
  name: "checkPermissions",
})
export class CheckPermissionsPipe implements PipeTransform {
  transform(userId: string, userAcls: Acl, teams: Team[], id?: string, type?: string): boolean {
    // Obtener la lista de equipos
    // Verificar el ACL de cada equipo en la lista
    for (const team of teams) {
      if (team.users.some(user => user.userId === userId)) {
        if (team.acl) {
          if (team.acl.type === "FULL") {
            return true;
          } else if (team.acl.type === "LIMITED") {
            if (team.acl.permitted[type]?.includes(id)) {
              return true;
            }
          }
        }
      }
    }

    // Verificar el ACL del usuario
    if (userAcls) {
      if (userAcls.type === "FULL") {
        console.log("is full");
        return true;
      } else if (userAcls.type === "LIMITED") {
        console.log("is limited");
        if (userAcls.permitted[type]?.includes(id)) {
          return true;
        }
      }
    }

    return false;
  }
}
