import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { TrainingService } from 'src/app/training/training.service';

@Injectable({
  providedIn: "root",
})
export class RegisterTrainingGuard  {

  constructor(
    private _trainService: TrainingService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(this._trainService.stateGetBusiness == 0){
        this._trainService.loading = false;
        this._trainService.permission = true;
        return true;
      }
      else{
        this._trainService.loading = false;
        this._trainService.permission = true;
        return true;
      }
  }
}
