import { Pipe, PipeTransform } from '@angular/core';
import { Group } from '../models/recruitment.model';

@Pipe({
  name: 'group'
})
export class GroupPipe implements PipeTransform {

  transform(group:any): Group {
    let dataGroup:Group={
      id: group.id,
      name: group.name,
      campaignId: group.campaign_id,
      amountSpend:group.amount_spend,
      created: group.created,
      diviceLocations:group.divice_locations,
      viewsLocations:group.views_locations
    }
    return dataGroup
  }

}
