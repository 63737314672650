import { environment } from "./environments/environment";


/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "us-east-1",
  "aws_cognito_region": "us-east-1",

  // "aws_user_pools_id": "us-east-1_i8nNTMW3T",
  // "aws_user_pools_web_client_id": "4jqe22600nihj07cgv7jno9keq",

  "aws_user_pools_id": "us-east-1_URoshfP3Q",
  "aws_user_pools_web_client_id": "18s0tft5v5nepjuq5ucqdlba7i",
  "oauth": {
    // "domain": "us-east-1-i8nntmw3t.auth.us-east-1.amazoncognito.com",
    "domain": "auth.edutin.com",
    "scope": [
      "email",
      "openid",
      "phone"
    ],
    // "redirectSignIn": "https://app.edutin.com/home/",
    // "redirectSignOut": "https://app.edutin.com/user/accounts",

    // "redirectSignIn": "http://localhost:4200/home/",
    // "redirectSignOut": "http://localhost:4200/user/accounts",

    // "redirectSignIn": "https://apptemp.edutin.com/home/",
    // "redirectSignOut": "https://apptemp.edutin.com/user/accounts",

    "redirectSignIn": "https://business.edutin.com/business",
    "redirectSignOut": "https://business.edutin.com/user/accounts",
    "responseType": "token"
  },
  "federationTarget": "COGNITO_USER_POOLS",
  "cookieStorage": {
    "domain": environment.appDomain == "http://127.0.0.1:4200" ? '127.0.0.1' : ".edutin.com",
    "path": "/",
    "expires": 365,
    "sameSite": "strict",
    "secure": environment.appDomain != "http://127.0.0.1:4200", //Si está en local será false, en producción será true
  },

};

export default awsmobile;
